* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color:white
}

body {
  font-family: sans-serif;
  background-color: #1E1E1E;
}

/* header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
} */

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.font-link {
  font-family: 'Abel', regular;
  font-family: 'Poppins', regular;
  font-family: 'Lato', regular;
  font-family: 'Inter', regular;
  font-family: 'Lekton', regular;
  font-family: 'Ultra', regular;
  font-family: 'Amiko', regular;
}

headerH1.ex1 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10%;
  padding-top: 140px;/* would allow correct alignment */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 94px;
  line-height: 1;
  background:  linear-gradient(96.46deg, #C87C00 3.72%, #8B3208 31.05%, rgba(83, 32, 15, 0.82) 68.54%, #010420 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.video-container {
  position: relative;
  width: 100%;
  height: 900px;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 900px;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}


/* content */

/* .content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
} */

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.rec-arrow {
  background-color: transparent !important;
  transition: all 0.3s ease;
  font-size: 1.5rem !important;  
  color: #382916 !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  min-width: 2.5rem !important;
  line-height: 2.5rem !important;

}


/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}

.bm-menu {
  background: #FFF8E4;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list {
  color: #382916;
  padding: 0.8em;
  font: 'Ultra';
}

.bm-burger-button {
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-button {
  
  position: relative;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 10px;
  float: right;
}

.hamburger-menu {
  position: absolute;
  top: 24px;
  right: 24px;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.menu-line {
  height: 3px;
  width: 100%;
  background-color: #382916;
  transition: transform 0.3s ease-in-out;
}

.menu-line.open:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.menu-line.open:nth-child(2) {
  transform: translateY(-1px) rotate(-45deg);
}

.menu-line.open:nth-child(3) {
  transform: translateY(-7px) rotate(45deg);
}

.menu-items {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  padding-top: 20px;
  background-color: #FFF8E4;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
}

.menu-items.open {
  transform: translateX(-300px);
}



.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #382916;
}

.menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0.8em;
  font: 'Ultra';
  font-size: 18px;
  color: #382916;
}

.menu-items li {
  padding: 12px 12px 12px 10px;
  cursor: pointer;
  color: #382916;
}

.menu-items li:hover {
  background-color: #FFF8E4;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.close-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
  transition: fill 0.3s ease-in-out;
 
}

.close-icon:hover {
  fill: grey;
}
